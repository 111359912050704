.instruction-title {
  label {
    font-weight: bold;
  }
}
.w {
  &-10 {
    width: 10%;
  }
  &-20 {
    width: 20%;
  }
  &-25 {
    width: 25%;
  }
  &-30 {
    width: 30%;
  }
  &-40 {
    width: 40%;
  }
  &-60 {
    width: 60%;
  }
}
