// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$siteColor: #036;
$hoverColor: #00306a;
$siteInverseColor: #fff;

$nav-font-size: 14px;
