@media (min-width: 992px) {
  .sidebar.sidebar-fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1030;
    .navbar {
      height: 100vh;
    }
  }
  .main-wrapper {
    margin-left: 256px;
  }
}

@media (max-width: 992px) {
  .dashboard-card {
    .dashboard-table {
      .OfdZy {
        min-height: 0;
      }
    }
  }
}
