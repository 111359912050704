.fow-app {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}
.white-space-pre {
  white-space: pre;
}
.flex {
  display: flex;
  min-height: 100vh;
  flex-direction: row;
}
.flex-row {
  flex-direction: row !important;
}
.align-items-center {
  align-items: center !important;
}
.badge {
  line-height: normal !important;
}
.sidebar {
  position: relative;
  display: flex;
  flex: 0 0 256px;
  flex-direction: column;
  order: -1;
  width: 256px;
  padding: 0;
  box-shadow: none;
  height: 100vh;

  .navbar-brand {
    position: sticky;
    top: 0;
    background-color: #2a2e37;
  }

  .navbar {
    flex-direction: column;
    padding: 0;
    height: 100vh;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.navbar-brand {
  display: flex;
  flex: 0 0 56px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  background: rgba(0, 0, 21, 0.2);
  width: 100%;
  margin: 0;
  text-align: center;

  .ap-logo {
    font-size: 20px;
    font-weight: bold;
  }
  .ap-text {
    color: #39f;
    font-size: 18px;
  }
}
.navbar-expand {
  .navbar-nav {
    flex-direction: column;
  }
}
.navbar-collapse {
  width: 100%;
  flex-direction: column;
}
.navbar-nav {
  width: inherit;

  .nav-item {
    font-size: $nav-font-size;
    display: flex;
    flex: 1 1;
    align-items: center;
    padding: 0.8445rem 1rem;
    text-decoration: none;
    white-space: nowrap;
    transition: background 0.3s, color 0.3s;
    color: rgba(255, 255, 255, 0.5);

    & > i {
      flex: 0 0 56px;
      height: 1.09375rem;
      font-size: 1.09375rem;
      text-align: center;
      transition: 0.3s;
      fill: currentColor;
    }
  }
  .active {
    background: #2a2e37;
  }
  .nav-title {
    color: rgba(255, 255, 255, 0.5);
    padding: 0.8445rem 1rem;
    margin-top: 5px;
    font-size: 80%;
    font-weight: 700;
    text-transform: uppercase;
    transition: 0.3s;
  }
}
.main-wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  min-width: 0;
  min-height: 100vh;
  position: relative;
}
.header {
  position: relative;
  min-height: 56px;
  background: #fff;
  width: calc(100% - 256px);
  box-shadow: 0px 5px 10px #ececec;

  &-fixed {
    position: fixed;
    top: 0;
    z-index: 10;
  }
  .header-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 56px;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .subheader {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    min-height: 48px;
    margin-top: -1px;
    border-top: 1px solid #d8dbe0;
    background: #fff;
    border-bottom: 1px solid #d8dbe0;
    padding-top: 0.5rem;
  }
  .breadcrumb {
    background-color: #fff;
    margin: 0;
  }
  .user-text {
    padding: 8px;
    font-size: 16px;
    font-weight: bold;
  }
}
.main-content {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
  padding: 1rem 0;
  margin-top: 60px;
  position: relative;
  height: calc(100vh - 126px);
  overflow: auto;
}
.footer {
  display: flex;
  flex: 0 0 50px;
  flex-wrap: wrap;
  align-items: center;
  height: 50px;
  padding: 0 1rem;
  color: #4f5d73;
  background: #ebedef;
  border-top: 1px solid #d8dbe0;
  box-shadow: 0px -2px 5px #cecece;
}
.invalid-feedback {
  display: block !important;
  font-size: 0.9rem;
}
.dt-table {
  font-size: 15px;
}
.dt-table .hcgwbo {
  white-space: break-spaces;
}
.required {
  color: #ea1537;
}
.fow-select {
  &__menu {
    z-index: 100 !important;
  }
}
.dependency-wrap {
  border-bottom: 1px solid #d8dbe0;
  margin-bottom: 10px;
}
.field-section .form-group {
  width: 100%;
  padding: 0px 5px;
}
.field-section {
  display: flex;
  width: 100%;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  margin: 0 0 18px;
  border-bottom: 1px solid #dddddd;
  h3 {
    font-weight: 600;
    margin: 0;
  }
}

.no-border-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 18px;
  h3 {
    font-weight: 600;
    margin: 0;
  }
}

.page-header-actions {
  display: flex;
  align-items: center;
  .btn {
    margin-left: 5px;
  }
}

.missing-wills {
  .missing-wills-content {
    .control-top-button {
      display: flex;
    }
    .missing-wills-form {
      background-color: #fff;
      padding: 15px 15px 0 15px;
      border: 1px solid #dddddd;
      border-radius: 10px;
    }
  }
}
.missing-will-details {
  .missing-will-details-table {
    background-color: #fff;
    .table-title {
      h4 {
        margin: 0;
        color: #2a2e37;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}
.background-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00000030;
  z-index: 99;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.not-found-box {
  background-color: #fff;
  padding: 9px 9px 0 9px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  width: 100%;
  margin: 0 auto;
  min-height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h4 {
    font-weight: 700;
    margin: 0;
    font-size: 26px;
  }

  p {
    width: 260px;
    margin: 10px 0;
  }

  .not-found-image {
    img {
      width: 110px;
      opacity: 0.5;
      margin-bottom: 18px;
    }
  }
}

.text-input {
  .error-focus-input {
    border-color: #f85656;
  }
}
.error-focus-input {
  border-color: #f85656;
}

.delete-will {
  text-align: center;
  font-size: 18px;
  color: #000;
  margin: 30px 0;
  span {
    font-weight: 700;
  }
}

.loading-button {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #f8f8f8 !important;
  filter: grayscale(1);
  pointer-events: none;
  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.check-box-input {
  .form-check-label {
    line-height: inherit;
    margin-top: 7px;
  }
}

.death-notice-details {
  .death-notice-death-image {
    vertical-align: middle;
    border: 1px solid #dee2e6;
    margin: 0 auto;
    width: 100%;
  }
  .death-notice-death-image-NA {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 30px;
  }

  .death-notice-personal-details-title {
    width: 15%;
    text-align: left;
  }

  .death-notice-personal-details-value {
    width: 30%;
  }
}

.profile-details {
  .profile-pic-logo-section {
    display: flex;
    align-items: center;
    justify-content: center;
    .profile-pic-logo-wrapper {
      height: 100%;
      max-width: 250px;
      .profile-pic-logo-image {
        vertical-align: middle;
        border: 1px solid #dee2e6;
        width: 100%;
        object-fit: cover;
        min-height: 100%;
        max-height: 304px;
      }
    }

    .profile-pic-logo-image-NA {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100%;
      font-size: 20px;
    }
  }

  .card-footer {
    padding-left: 0;
  }
}

.charity-details,
.pages-details,
.meta-details,
.pricing-details,
.terminologies-details,
.faqs-details,
.questions-details,
.permission-details,
.living-will-form-details,
.living-will-details,
.user-details {
  .card-footer {
    padding: 1.25rem;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.charity-details,
.pages-details,
.meta-details,
.questions-details,
.pricing-details,
.living-will-form-details,
.living-will-details,
.terminologies-details {
  .btn-group {
    width: 100%;
    label {
      justify-content: center;
    }
    .radio-group-btn {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .card-footer {
    padding: 1.25rem;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.user-details {
  .details-table {
    .details-title {
      width: 15%;
      text-align: left;
    }
    .details-value {
      width: 30%;
    }
  }
}

.meta-details {
  .meta-page-image-section {
    display: flex;
    align-items: center;
    justify-content: center;

    .meta-page-image-wrapper {
      height: 100%;
      max-width: 100%;

      .meta-page-image {
        vertical-align: middle;
        border: 1px solid #dee2e6;
        width: 100%;
        object-fit: inherit;
        min-height: 95%;
        max-height: 50px;
      }
    }

    .meta-page-image-NA {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100%;
      font-size: 20px;
    }
  }
}

.living-will-form-details {
  .pdf-preview-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .pdf-preview-wrapper {
      height: 100%;
      margin-top: 70px;
      margin-bottom: 70px;
      width: 100%;
    }

    .pdf-preview-NA {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100%;
      font-size: 20px;
    }
  }
}

.default-table {
  background-color: #fff;
  .table-title {
    background-color: #fff;
    h4 {
      margin: 0;
      color: #39f;
      font-size: 18px;
      font-weight: 600;
    }
  }
  th {
    color: #2a2e37;
    background-color: #eff7ff;
    display: table-cell;
  }
}
.living-will-details {
  .living-will-details-table {
    background-color: #fff;
    .table-title {
      h4 {
        margin: 0;
        color: #2a2e37;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}
.will-status {
  .will-status-content {
    .control-top-button {
      display: flex;
    }
    .will-export-form,
    .will-status-form {
      background-color: #fff;
      padding: 15px 15px 0 15px;
      border: 1px solid #dddddd;
      border-radius: 10px;
    }
  }
}

.dashboard-card {
  .dashboard-table {
    .OfdZy {
      min-height: 330px;
    }
  }
}

.invoice-find-search-pin-form {
  .or {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 0.5px;
      background-color: rgba(0, 0, 0, 0.1);
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    span {
      background-color: #f8fafc;
      position: relative;
      z-index: 1;
      padding: 1px 10px;
    }
  }

  .horizontal-border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.invoices {
  .invoices-content {
    .control-top-button {
      display: flex;
    }
    .invoices-form {
      background-color: #fff;
      padding: 15px 15px 0 15px;
      border: 1px solid #dddddd;
      border-radius: 10px;
    }
  }
}

.button-link {
  padding: 6px 15px;
  border: 1px solid #999;
  border-radius: 3px;
  &:hover {
    text-decoration: none;
  }
  &.secondary {
    color: #fff;
    background-color: #999;
    &:hover {
      background-color: #666;
    }
  }
}

.access-denied-container {
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon-container {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 50%;
  }
}

.permission-group {
  .permission-group-header {
    background: #eee;
    padding: 10px 15px;
    border-radius: 10px;
  }

  label {
    display: flex;
    align-items: center;
    gap: 5px;
    width: max-content;
    cursor: pointer;
  }
}
